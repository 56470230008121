import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../../../assets/Images/logo.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (path) => {
    if (location.pathname !== path) {
      setIsMenuOpen(false);
    }
  };

  return (
    <header className={`header-area header-sticky wow slideInDown ${isMenuOpen ? 'menu-open' : ''}`} data-wow-duration="0.75s" data-wow-delay="0s">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="main-nav">
                <Link to="/" className="logo" onClick={() => handleLinkClick('/')}>
                  <img src={logo} alt="Chain App Dev" />
                </Link>
                <ul className={`nav ${isMenuOpen ? 'show' : ''}`}>
                  <li className="scroll-to-section"><Link to="/" className={location.pathname === '/' ? 'active' : ''} onClick={() => handleLinkClick('/')}>Home</Link></li>
                  <li className="scroll-to-section"><Link to="/Maps" className={location.pathname === '/Maps' ? 'active' : ''} onClick={() => handleLinkClick('/Maps')}>Maps</Link></li>
                  <li className="scroll-to-section"><Link to="/About" className={location.pathname === '/About' ? 'active' : ''} onClick={() => handleLinkClick('/About')}>About</Link></li>
                  <li className="scroll-to-section"><Link to="/Contact" className={location.pathname === '/Contact' ? 'active' : ''} onClick={() => handleLinkClick('/Contact')}>Contact</Link></li>
                  <li className="scroll-to-section"><Link to="/Developing" className={location.pathname === '/Developing' ? 'active' : ''} onClick={() => handleLinkClick('/Developing')}>Developing</Link></li>
                  <li className="scroll-to-section"><Link to="/Newsletter" className={location.pathname === '/Newsletter' ? 'active' : ''} onClick={() => handleLinkClick('/Newsletter')}>Newsletter</Link></li>
                </ul>
              <div className={`menu-trigger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                <span>Menu</span>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
