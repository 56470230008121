import React, { useState } from 'react';
import subscribeToNewsletter from '../../ApiNewsLetter/NewsLetter';
import './Newsletter.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      const result = await subscribeToNewsletter(email);
      setMessage(result.message);
      if (result.success) {
        setEmail('');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="newsletter-section">
      <div className="container">
        <div className="section-heading">
          <h4>Stay Connected with Our <em>Newsletter</em></h4>
          <img src={require('../../../assets/Images/heading-line-dec.png')} alt=""/>
          <p>Get the latest updates on our projects, spiritual insights, and community news</p>
        </div>
        <div className="newsletter-content">
          <div className="newsletter-form">
            <h3>Join Our Mailing List</h3>
            <form onSubmit={handleSubmit}>
              <input 
                type="email" 
                placeholder="Enter your email address" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                required 
              />
              <button type="submit" className="gradient-button" disabled={isLoading}>
                {isLoading ? 'Subscribing...' : 'Subscribe'}
              </button>
            </form>
            {message && (
              <p className={`message ${message.includes('successful') ? 'success' : 'error'}`}>
                {message}
              </p>
            )}
            <p className="privacy-note">We respect your privacy. Unsubscribe at any time.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;