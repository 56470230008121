import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import { Link } from 'react-router-dom';
import subscribeToNewsletter from '../../ApiNewsLetter/NewsLetter';
import logo from '../../../assets/Images/logo.png';



const Footer = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const result = await subscribeToNewsletter(email);
      if (result.success) {
        setEmail('Subscription successful!');
      } else {
        setEmail(result.message || 'Subscription failed. Please try again.');
      }
    } catch (error) {
      setEmail('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }

    setTimeout(() => {
      setEmail('');
    }, 3000);
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div id="Footer">
      <footer id="newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>Sign up for insider updates</h4>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-3">
              <form id="search" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <fieldset>
                      <input
                        type="text"
                        name="email"
                        className="email"
                        placeholder="Email Address..."
                        autoComplete="on"
                        required
                        value={email}
                        onChange={handleInputChange}
                        readOnly={isLoading}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <fieldset>
                      <button type="submit" className="main-button" disabled={isLoading}>
                        {isLoading ? 'Subscribing...' : 'Subscribe Now'} <i className="fa fa-angle-right"></i>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-widget">
                <h4>Contact Us</h4>
                <p>Email: Admin@mychurchofchrist.com</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget">
                <h4>Quick Links</h4>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/Maps">Maps</Link></li>
                  <li><Link to="/About">About</Link></li>
                  <li><Link to="/#Contact">Contact</Link></li>
                  <li><Link to="/Developing">Developing</Link></li>
                  <li><Link to="/#newsletter">Newsletter</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget">
                <h4>Featured Channels</h4>
                <ul>
                  <li><a href="https://www.youtube.com/@faithuncovered" target="_blank" rel="noopener noreferrer">Faith Uncovered</a></li>
                  <li><a href="https://www.youtube.com/@ChristianLandmark" target="_blank" rel="noopener noreferrer">Christian Landmark</a></li>
                  <li><a href="https://www.youtube.com/@PureandSimpleBible" target="_blank" rel="noopener noreferrer">Pure and Simple Bible</a></li>
                  <li><a href="https://www.youtube.com/@PatternTheology" target="_blank" rel="noopener noreferrer">Pattern Theology</a></li>
                  <li><a href="https://www.youtube.com/@LetTheBibleSpeak" target="_blank" rel="noopener noreferrer">Let The Bible Speak</a></li>
                  <li><a href="https://www.youtube.com/@studiesfromthebible" target="_blank" rel="noopener noreferrer">Studies From The Bible</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget">
                <h4>About Our Company</h4>
                <div className="logo">
                     <img src={logo} alt="Company Logo" />
                </div>
                <p>
                We create innovative digital tools to support your faith, offering AI-powered Bible study, live translations, and a vast Christian literature library. Our mission is to connect and inspire believers globally.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="copyright-text">
                <p>
                  Copyright © 2024 My Church of Christ. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;