import axios from 'axios';

const API_URL = '/api';

const logVisitor = async () => {
  try {
    const response = await axios.post(`${API_URL}/log`, { 
      path: window.location.pathname 
    });
    console.log('Visitor logged:', response.data.message);
    return {
      success: true,
      message: response.data.message
    };
  } catch (error) {
    console.error('Error logging visitor:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred while logging visitor.'
    };
  }
};

export default logVisitor;

