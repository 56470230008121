import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './MapComponent.css';
import { Link } from 'react-router-dom';


const API_URL = '/api';

const getGoogleMapsUrl = (lat, lng, origin = null) => {
  let url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
  if (origin) {
    url += `&origin=${origin.lat},${origin.lng}`;
  }
  return url;
};

const renderContactInfo = (name, phone, email) => {
  if (!name) return null;
  return `
    <p>
      <strong>${name}</strong><br />
      ${phone ? `Phone: <a href="tel:${phone}">${phone}</a><br />` : ''}
      ${email ? `Email: <a href="mailto:${email}">${email}</a>` : ''}
    </p>
  `;
};

const createInfoWindowContent = (church, userLocation) => {
  return `
    <div style="font-family: Arial, sans-serif; font-size: 14px; width: 300px; max-height: 400px; overflow-y: auto;">
      <h3 style="margin: 0 0 10px; font-size: 18px;">${church.name}</h3>
      <p>
        <strong>Address: </strong>
        <a href="${getGoogleMapsUrl(church.lat, church.lon, userLocation)}" target="_blank" rel="noopener noreferrer" style="color: #0066cc; text-decoration: none;">
          ${church.address}
        </a>
      </p>
      <p><strong>Sunday AM:</strong> ${church['Sunday AM']}</p>
      ${church['Sunday PM'] ? `<p><strong>Sunday PM:</strong> ${church['Sunday PM']}</p>` : ''}
      ${church['Midweek'] ? `<p><strong>Midweek:</strong> ${church['Midweek']}</p>` : ''}
      <h4 style="margin: 10px 0 5px;">Contacts:</h4>
      ${renderContactInfo(church['Contact 1 Name'], church['Contact 1 Phone number'], church['Contact 1 email'])}
      ${renderContactInfo(church['Contact 2 Name'], church['Contact 2 Phone number'], church['Contact 2 email'])}
      ${renderContactInfo(church['Contact 3 Name'], church['Contact 3 Phone number'], church['Contact 3 email'])}
      ${church['Website'] ? `<p><strong>Website:</strong> <a href="${church['Website']}" target="_blank" rel="noopener noreferrer" style="color: #0066cc; text-decoration: none;">${church['Website']}</a></p>` : ''}
    </div>
  `;
};

const loadGoogleMapsApi = () => {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      resolve(window.google.maps);
      return;
    }

    const script = document.createElement('script');
    script.src = `${API_URL}/maps/js`;
    script.async = true;
    script.defer = true;
    window.initMap = () => {
      if (window.google && window.google.maps) {
        resolve(window.google.maps);
      } else {
        reject(new Error('Google Maps failed to load'));
      }
    };
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

const GoogleMapComponent = () => {
  const [churches, setChurches] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [map, setMap] = useState(null);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const mapRef = useRef(null);
  const infoWindowRef = useRef(null);
  const markersRef = useRef([]);

  const clearMarkers = useCallback(() => {
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];
  }, []);

  const initMap = useCallback(() => {
    if (!mapRef.current || !window.google || !window.google.maps || churches.length === 0) return;

    const googleMaps = window.google.maps;
    const newMap = new googleMaps.Map(mapRef.current, {
      center: { lat: 39.8283, lng: -98.5795 },
      zoom: 5,
      mapTypeControl: false,
      streetViewControl: false,
      mapTypeId: 'roadmap',
      scrollwheel: true  // Add this line
    });
    setMap(newMap);
    infoWindowRef.current = new googleMaps.InfoWindow();

    clearMarkers();

    churches.forEach((church) => {
      if (typeof church.lat === 'number' && typeof church.lon === 'number' && !isNaN(church.lat) && !isNaN(church.lon)) {
        const marker = new googleMaps.Marker({
          map: newMap,
          position: { lat: church.lat, lng: church.lon },
          title: church.name
        });
        marker.addListener('click', () => {
          infoWindowRef.current.setContent(createInfoWindowContent(church));
          infoWindowRef.current.open(newMap, marker);
        });
        markersRef.current.push(marker);
      }
    });
  }, [churches, clearMarkers]);

  useEffect(() => {
    const fetchChurches = async () => {
      try {
        const response = await axios.get(`${API_URL}/churches`);
        const validChurches = response.data.filter(church => 
          typeof church.lat === 'number' && typeof church.lon === 'number' && !isNaN(church.lat) && !isNaN(church.lon)
        );
        setChurches(validChurches);
      } catch (error) {
        console.error('Error fetching churches:', error);
      }
    };
    fetchChurches();
  }, []);

  useEffect(() => {
    const loadMap = async () => {
      try {
        await loadGoogleMapsApi();
        setGoogleMapsLoaded(true);
      } catch (error) {
        console.error('Error loading Google Maps:', error);
      }
    };
    loadMap();
  }, []);

  useEffect(() => {
    if (googleMapsLoaded && churches.length > 0) {
      initMap();
    }
  }, [googleMapsLoaded, churches, initMap]);

  const handleSearchInputChange = useCallback(async (e) => {
    const input = e.target.value;
    setSearchInput(input);

    if (input.length > 2) {
      try {
        const response = await axios.get(`${API_URL}/places/autocomplete?input=${input}`);
        setSearchResults(response.data);
      } catch (error) {
        console.error('Error fetching place predictions:', error);
      }
    } else {
      setSearchResults([]);
    }
  }, []);

  const handlePlaceSelect = useCallback(async (placeId) => {
    try {
      const response = await axios.get(`${API_URL}/places/details?placeId=${placeId}`);
      const location = response.data.geometry.location;
      
      if (map && location && typeof location.lat === 'number' && typeof location.lng === 'number') {
        map.panTo(location);
        map.setZoom(10);

        new window.google.maps.Marker({
          position: location,
          map: map,
          icon: {
            url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
            scaledSize: new window.google.maps.Size(50, 50),
          }
        });
      }

      setSearchInput('');
      setSearchResults([]);
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  }, [map]);

return (
  <div className="google-map-section">
    <div className="map-header">
      <h2>Find a Church Near You</h2>
      <p>Use the map below to locate churches in your area.</p>
    </div>
    <div className="map-wrapper">
      <div className="map-container">
        <div className="search-box">
          <input
            className="search-input"
            type="text"
            placeholder="Search for a location"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          {searchResults.length > 0 && (
            <ul className="search-results">
              {searchResults.map((result) => (
                <li key={result.place_id} onClick={() => handlePlaceSelect(result.place_id)}>
                  {result.description}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div ref={mapRef} style={{ height: '100%', width: '100%' }} />
      </div>
    </div>
    <div className="map-footer">
      <p>If you notice any errors on the map, please let us know.</p>
      <Link to="/contact" className="contact-button">Contact Us</Link>
    </div>
  </div>
);

  
};

export default GoogleMapComponent;