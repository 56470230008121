import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainBody.css'; // For any custom styles

const LandingPage = () => {
  return (
    <>
      <div className="main-banner" id="top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <div className="left-content header-text">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2>Now Live: Our New Digital Directory!</h2>
                        <p>Stay informed with the most accurate and up-to-date information. Locate the nearest congregation and get instant directions directly to your device.</p>
                      </div>
                      <div className="col-lg-12">
                        <div className="white-button first-button scroll-to-section">
                          <Link to="/Maps">Try Now<i className="fab fa-apple"></i></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="right-image">
                    <img src={require('../../../assets/Images/slider-dec.png')} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing" className="pricing-tables">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>Get Ready for Our Next Big Release -<em> Coming Soon!</em></h4>
                <img src={require('../../../assets/Images/heading-line-dec.png')} alt="" />
                <p>Three innovative tools to enrich your faith: translation, AI-powered Bible study, and Christian literature at your fingertips</p>
              </div>
            </div>
            <PricingItem 
              price="Coming Soon"
              title="Live Translation"
              features={[
                "Nearly Live-Translation",
                "Over 95 Languages",
                "Available Online and Offline",
                "AI-Powered Voice",
                "Contextual Accuracy",
                "Multi-Speaker Detection"
              ]}
              
            />
            <PricingItem 
              price="Coming Soon"
              title="AI Study Companion"
              features={[
                "AI-Powered Chatbot",
                "AI-Powered Search Tools",
                "Multiple Bible Translations",
                "Commentaries",
                "Find Videos",
                "And Much More!"
              ]}
              /* disabledFeatures={[4, 5]} */
              isPro={true}           
                />
            <PricingItem 
              price="Coming Soon"
              title="Christian Bookstore"
              features={[
                "Books",
                "Commentaries",
                "Booklets",
                "Pamphlets",
                "Bible Study Guides",
                "And More"
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const PricingItem = ({ price, title, features, disabledFeatures = [], isPro = false }) => (
  <div className="col-lg-4">
    <div className={`pricing-item-${isPro ? 'pro' : 'regular'}`}>
      <span className="price">{price}</span>
      <h4>{title}</h4>
      <div className="icon">
        <img src={require('../../../assets/Images/TranslatorLogo.webp')} alt="" />
      </div>
      <ul>
        {features.map((feature, index) => (
          <li key={index} className={disabledFeatures.includes(index) ? 'non-function' : ''}>
            {feature}
          </li>
        ))}
      </ul>
      <div className="border-button">
        <a href="/Developing">Learn More</a>
      </div>
    </div>
  </div>
);

export default LandingPage;