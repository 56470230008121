import React from 'react';
import './Developing.css';

const Developing = () => {
  const projects = [
    {
      title: "Live Translation",
      description: "Our Live Translation tool is set to revolutionize communication across language barriers. Utilizing cutting-edge AI technology, this tool provides near-instantaneous translation in over 95 languages, making it an indispensable asset for global communication.",
      features: [
        "Nearly Live-Translation for seamless communication",
        "Support for over 95 languages",
        "Available both online and offline",
        "AI-Powered voice technology for natural-sounding translations",
        "Contextual accuracy to capture nuances and idioms",
        "Multi-Speaker Detection"
      ],
      benefits: [
        "Break down language barriers in real-time",
        "Enhance cross-cultural communication and understanding",
        "Improve accessibility in multilingual settings",
        "Perfect for mission work and live translation in church services",
      ],
      comingSoon: "We're in the final stages of development. Stay tuned for our  alpha launch!"
    },
    {
      title: "AI Study Companion",
      description: "The AI Study Companion is an innovative tool designed to deepen your understanding of scripture. By combining advanced AI technology with comprehensive biblical resources, this companion will transform your Bible study experience. Think of it as a modern, efficient commentary, streamlining your study process without replacing traditional methods.",
      features: [
        "AI-Powered Chatbot for insightful discussions and explanations",
        "Advanced search tools for comprehensive study",
        "Access to multiple Bible translations for comparative study",
        "Integration with commentaries for deeper insights",
        "Video content suggestions for visual learners",
        "Personalized study plans and recommendations"
      ],
      benefits: [
        "Gain deeper insights into scripture with AI-assisted analysis",
        "Efficiently navigate complex theological concepts",
        "Personalize your Bible study experience to your learning style",
        "Access a wealth of resources in one integrated platform"
      ],
      comingSoon: "aplpha testing is  in weeks from being underway. Contact us if you would like to be a aplpha tester! "
    },
    {
      title: "Christian Bookstore",
      description: "Our digital Christian Bookstore will bring a vast library of Christian literature to your fingertips. From classic works to contemporary studies, this platform will be your go-to resource for spiritual growth and biblical understanding.",
      features: [
        "Extensive collection of Christian books across various genres",
        "In-depth commentaries from renowned scholars",
        "Variety of booklets on specific topics for quick study",
        "Downloadable pamphlets for easy sharing and reference",
        "Comprehensive Bible study guides for individual or group use",
        "Regular updates with new releases and featured content"
      ],
      benefits: [
        "Build a comprehensive digital Christian library",
        "Access resources anytime, anywhere on your devices",
        "Discover new authors and perspectives to enrich your faith",
        "Support your personal and group Bible studies with quality resources"
      ],
      comingSoon: "We're curating our collection and finalizing our platform. Expect to dive into a world of Christian literature very soon!"
    }
  ];

  return (
    <div className="developing-section">
      <div className="container">
        <div className="section-heading">
          <h4>Upcoming <em>Projects</em></h4>
          <img src={require('../../../assets/Images/heading-line-dec.png')} alt=""/>
          <p>Discover the innovative tools we're developing to enhance your spiritual journey</p>
        </div>
        {projects.map((project, index) => (
          <div key={index} className="project-details">
            <div className="coming-soon">Coming Soon</div>
            <h3>{project.title}</h3>
            <p className="project-description">{project.description}</p>
            <div className="features-benefits">
              <div className="features">
                <h4>Key Features:</h4>
                <ul>
                  {project.features.map((feature, fIndex) => (
                    <li key={fIndex}>{feature}</li>
                  ))}
                </ul>
              </div>
              <div className="benefits">
                <h4>Benefits:</h4>
                <ul>
                  {project.benefits.map((benefit, bIndex) => (
                    <li key={bIndex}>{benefit}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="project-coming-soon">
              <h4>Coming Soon:</h4>
              <p>{project.comingSoon}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Developing;