import React from 'react';
import './Contact.css';

const Contact = () => {
  const email = 'Admin@mychurchofchrist.com'; // Replace with your actual email

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(email);
    alert('Email copied to clipboard!');
  };

  return (
    <div className="contact-section">
      <div className="container">
        <div className="section-heading">
          <h4>Get in <em>Touch</em></h4>
          <img src={require('../../../assets/Images/heading-line-dec.png')} alt=""/>
          <p>We'd love to hear from you. Feel free to reach out with any questions or feedback.</p>
        </div>
        <div className="contact-content">
          <div className="contact-info">
            <div className="email-display">
              <h3>Contact Us</h3>
              <p>You can reach us at:</p>
              <div className="email-box">
                <span className="email-text">{email}</span>
                <button onClick={handleCopyEmail} className="copy-button">
                  Copy
                </button>
              </div>
            </div>
            <div className="additional-info">
              <h4>What to expect:</h4>
              <ul>
                <li>We typically respond within 1-2 business days</li>
                <li>For urgent matters, please include "URGENT" in the subject line</li>
                <li>We're happy to assist with any questions about our services or upcoming projects</li>
              </ul>
            </div>
          </div>
          <div className="contact-image">
            <img src={require('../../../assets/Images/about-right-dec.png')} alt="Contact decoration" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;