import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h4>About <em>Us</em></h4>
              <img src={require('../../../assets/Images/Aaron and Chris.png')} alt=""/>
              <p>Our mission is to make Bible study more fruitful, enabling efficient and insightful exploration of scripture for spiritual growth.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="about-left-image">
              <img src={require('../../../assets/Images/Aaron and Chris.png')} alt=""/>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="about-right-content">
              <h4>Our Mission</h4>
              <p>In our calling as Church of Christ preachers, we embrace both our timeless faith and the innovative tools of the modern age. We recognize the immense potential of technology to amplify our efforts in serving the Lord, viewing these advancements not as departures from tradition, but as extensions of our longstanding mission to spread the Gospel.
              While our methods may appear novel, they are, in essence, contemporary expressions of age-old practices. Our AI-assisted tools, much like the concordances and commentaries of yesteryear, serve to deepen our understanding and enhance our ministry. Similarly, our translation technology doesn't alter the sanctity of worship; rather, it bridges linguistic divides, fostering unity in the body of Christ. We remain steadfast in our commitment to the old paths, cherishing our heritage and the brotherhood. Our goal is not to innovate worship itself, but to harness modern capabilities in service of our unchanging mission. By embracing these tools, we strive to be more effective teachers, more diligent Bible students, and more capable workers in the Lord's vineyard. We understand that while the medium of communication may evolve, the message - the Gospel - remains constant and true. In this way, we aim to use our God-given talents and the resources of our time to fulfill the Great Commission, reaching hearts and minds in ways that resonate in today's world.</p>
              <h4>Our Goals</h4>
              <div className="row">
                <div className="col-lg-6">
                  <div className="about-item">
                    <h4>1</h4>
                    <h5>Faithful</h5>
                    <p>Our goal is to provide tools that will help Christians stay faithful to the Word, deepening their understanding and commitment.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-item">
                    <h4>2</h4>
                    <h5>Future</h5>
                    <p>We aim to protect our future by honoring the past, bridging traditional teachings with modern technology.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-item">
                    <h4>3</h4>
                    <h5>Fellowship</h5>
                    <p>We strive to foster stronger fellowship among believers by creating digital spaces for connection and shared study.</p>
                  </div>
                </div>

              </div>
              <div className="gradient-button">
                <a href="/contact">Get in Touch</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;