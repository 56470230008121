import axios from 'axios';

const API_URL = '/api';
// 'http://localhost:5001/api';
const subscribeToNewsletter = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/subscribe`, { email });
    return {
      success: response.data.success,
      message: response.data.message
    };
  } catch (error) {
    console.error('Error:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred. Please try again later.'
    };
  }
};

export default subscribeToNewsletter;