import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/HomePage/Header/Header';
import Footer from './components/HomePage/Footer/Footer';
import GoogleMapComponent from './components/Maps/GoogleMapComponent';
import LandingPage from './components/HomePage/MainBody/MainBody';
import About from './components/HomePage/About/About';
import Developing from './components/HomePage/Developing/Developing';
import Newsletter from './components/HomePage/Newsletter/Newsletter';
import Contact from './components/HomePage/Contact/Contact';
import logVisitor from './components/API/Logger'; 

import './App.css';

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    logVisitor();
  }, [location]);

  return (
    <div className="App" style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh'
    }}>
      <Header />
      <main style={{ flexGrow: 1 }}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/maps" element={<GoogleMapComponent />} />
          <Route path="/about" element={<About />} />
          <Route path="/developing" element={<Developing />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;